@import "k5-variables.less";
@import "mixins.less";
@import "jplayer.blue.monday.less";
.header-top {
  height:46px;
  margin-bottom:(@line-height-computed/4);
  @media (max-width: @screen-xs-max) {
    height:122px;
  }
}
.footer-copyright,.header-top{background-color:@brand-primary;}
.header-top .logo{padding:8px 0 0 0;}
#searchForm,.header-top .usertop{padding:5px 0 0 0;}
.header-top .btn-link,.footer-copyright .btn-link{color:#fff;}
.borderly{border-left:4px solid @brand-primary;padding-left:4px;}
.shareButton{margin:10px 0px;padding:0;}
.shareButton li a{
margin:0px;padding:0 4px !important;
height:20px;width:20px;}

.header-top .logo,.footer-copyright .logo{margin-bottom: 10px;}
.pagination > li > a, .pagination > li > span{
 @media (max-width: @screen-xs-max) {
    padding: 10px 16px;
  }
}

//Google AdWords.
.pub-douxavenir{background-image:url('../img/douxavenir320.jpg');}  
.pub-charmetelrose{background-image:url('../img/charmetelrose320.jpg');}

.alyroxresponsive { width: 320px; height: 100px;margin:auto; }
.alyroxcarreresponsive { width: 320px; height: 100px;margin:auto;}
.pubKwp{margin:0 auto;display:block;width:320px;height:100px;background-repeat: none;background-color: #fff;background-position: center center;}
.myads{padding:10px;margin:10px auto;}
.intextads{margin-left:-20px;margin-right:-20px;}
@media(max-width: 400px)
{
	.adaptativeads,.adaptativeads .cm-8,.adaptativeads .cs-12,.adaptativeads .cm-4{padding:0;}
	.adaptativeads .row{margin:0;}
	.myads,.intextads{text-align:center;padding:0;border:none;}
}
@media(min-width: 400px) { 
	.alyroxcarreresponsive { width: 180px; height: 150px; }
	.myads,.intextads{text-align:center;padding:0;border:none;}
	.alyroxresponsive { width: 468px; height: 60px; } 
	.pub-douxavenir{background-image:url('../img/douxavenir320.jpg');width:320px;height:100px}
	.pub-charmetelrose{background-image:url('../img/charmetelrose320.jpg');width:320px;height:100px}
}
@media(min-width: 800px) { 
	.alyroxcarreresponsive { width: 300px; height: 600px; }
	.alyroxresponsive { width: 468px; height: 90px; } 
	.pub-douxavenir{background-image:url('../img/douxavenir520.jpg');width:520px;height:100px}
	.pub-charmetelrose{background-image:url('../img/charmetelrose520.jpg');width:520px;height:100px}
}
@media(min-width: 1025px) {
	 .alyroxcarreresponsive { width: 300px; height: 600px;} 
	 .alyroxresponsive { width: 728px; height: 90px; } 
	 .pub-douxavenir{background-image:url('../img/douxavenir708.jpg');width:708px;height:89px}
	 .pub-charmetelrose{background-image:url('../img/charmetelrose708.jpg');width:708px;height:89px}
	
}

