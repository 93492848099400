.pagination {
  display: inline-block;
  padding-left: 0;
  margin: @line-height-computed 0;
  > li {
    display: inline; // Remove list-style and block-level defaults
    > a,
    > span {
      position: relative;
      float: left; // Collapse white-space
      padding: @padding-base-vertical @padding-base-horizontal;
      line-height: @line-height-base;
      text-decoration: none;
      color: @pagination-color;
      background-color: @pagination-bg;
      border: 1px solid @pagination-border;
      margin-left: -1px;
    }
    &:first-child {
      > a,
      > span {
        margin-left: 0;
      }
    }    
  }

  > li > a,
  > li > span {
    &:hover,
    &:focus {
      color: @pagination-hover-color;
      background-color: @pagination-hover-bg;
      border-color: @pagination-hover-border;
    }
  }

  > .active > a,
  > .active > span {
    &,
    &:hover,
    &:focus {
      z-index: 2;
      color: @pagination-active-color;
      background-color: @pagination-active-bg;
      border-color: @pagination-active-border;
      cursor: default;
    }
  }
}
