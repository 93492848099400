.form-control {
  display: block;
  width: 100%;
  height: @input-height-base; 
   padding: @padding-base-vertical @padding-base-horizontal;
  font-size: @font-size-base;
  line-height: @line-height-base;
  color: @input-color;
  background-color: @input-bg;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid @input-border;
  .form-control-focus();
  .placeholder();
}
.form-group {
  margin-bottom: 15px;
}