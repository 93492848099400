html {
  font-family: sans-serif; // 1
  -ms-text-size-adjust: 100%; // 2
  -webkit-text-size-adjust: 100%; // 2
}
body {
  margin: 0;
}
aside,
footer,
header,
section,
 {
  display: block;
}
a {
  background: transparent;
}
a:active,
a:hover {
  outline: 0;
}
strong {
  font-weight: bold;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
img {
  border: 0;
}

button,
input,
select,
textarea {
  color: inherit; // 1
  font: inherit; // 2
  margin: 0; // 3
}

button {
  overflow: visible;
  text-transform: none;
}
button,
html input[type="button"], // 1
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button; // 2
  cursor: pointer; // 3
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
input {
  line-height: normal;
}