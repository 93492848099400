* {
  .box-sizing(border-box);
}
*:before,
*:after {
  .box-sizing(border-box);
}
html {
  font-size: 10px;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

body {
  font-family: @font-family-base;
  font-size: @font-size-base;
  line-height: @line-height-base;
  color: @text-color;
  background-color: @body-bg;
}

// Reset fonts for relevant elements
input,
button
 {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
a {
  color: @link-color;
  text-decoration: none;

  &:hover,
  &:focus {
    color: @link-hover-color;
    text-decoration: underline;
  }
}
img {
  vertical-align: middle;
}
.img-responsive {
  .img-responsive();
}
