.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: (@line-height-computed/2);
  background-color: @well-bg;
  border: 1px solid @well-border;
}

// Sizes
.well-lg {
  padding: 24px;
}
.well-sm {
  padding: 9px;
}
