.list-group {
  margin-bottom: 20px;
  padding-left: 0; // reset padding because ul and ol
}
.list-group-item {
  position: relative;
  display: block;
  padding: 10px 15px;
  margin-bottom: -1px;
  background-color: @list-group-bg;
  border: 1px solid @list-group-border;
  &:last-child {
    margin-bottom: 0;
  }
  > .badge {
    float: right;
  }
  > .badge + .badge {
    margin-right: 5px;
  }
}
a.list-group-item {
  color: @list-group-link-color;

  .list-group-item-heading {
    color: @list-group-link-heading-color;
  }
  &:hover,
  &:focus {
    text-decoration: none;
    color: @list-group-link-hover-color;
    background-color: @list-group-hover-bg;
  }
}
.list-group-item-heading {
  margin-top: 0;
  margin-bottom: 5px;
  color:@brand-primary !important;
}
.list-group-item-text {
  margin-bottom: 0;
  line-height: 1.3;
}
