.jp-audio *:focus,
.jp-audio-stream *:focus,
.jp-video *:focus { outline: none; }
.jp-audio button::-moz-focus-inner,
.jp-audio-stream button::-moz-focus-inner,
.jp-video button::-moz-focus-inner {border: 0; }

.jp-audio,
.jp-audio-stream,
.jp-interface {position: relative;width: 100%;}
.jp-audio .jp-interface {height: 40px; }
.jp-audio-stream .jp-interface { height: 40px; }

/* @group CONTROLS */
.jp-controls-holder {
  clear: both;
  width: 440px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  top: -8px;
  /* This negative value depends on the size of the text in jp-currentTime and jp-duration */ }

.jp-interface .jp-controls {margin: 0;padding: 0;overflow: hidden; }

.jp-audio .jp-controls {/*padding: 20px 20px 0 20px;*/ }

.jp-audio-stream .jp-controls {}

.jp-controls button {
  display: block;
  float: right;
  overflow: hidden;
  text-indent: -9999px;
  border: none;
  cursor: pointer; }

.jp-play {
  width: 40px;
  height: 40px;}
.jp-play-list{
	width: 40px;
  height: 40px;
   background: url("../img/jplayer.blue.monday.jpg") 0 0 no-repeat; 
   position:absolute;
   left:0;

}
.jp-play {
  background: url("../img/jplayer.blue.monday.jpg") 0 0 no-repeat; }

.jp-play:focus {
  background: url("../img/jplayer.blue.monday.jpg") -41px 0 no-repeat; }

.jp-state-playing .jp-play {
  background: url("../img/jplayer.blue.monday.jpg") 0 -42px no-repeat; }

.jp-state-playing .jp-play:focus {
  background: url("../img/jplayer.blue.monday.jpg") -41px -42px no-repeat; }

.jp-stop, .jp-previous, .jp-next {
  width: 28px;
  height: 28px;
  margin-top: 6px; }

.jp-stop {
  background: url("../img/jplayer.blue.monday.jpg") 0 -83px no-repeat;
  margin-left: 10px; }

.jp-stop:focus {
  background: url("../img/jplayer.blue.monday.jpg") -29px -83px no-repeat; }
/* @end */
/* @group progress bar */
.jp-progress {
  overflow: hidden;
  background-color: #ddd;padding:0 !important; }

.jp-audio .jp-progress {
  margin-top: 12px;
  height: 15px; }

.jp-audio .jp-type-single .jp-progress {}

.jp-seek-bar {
  background: url("../img/jplayer.blue.monday.jpg") 0 -202px repeat-x;
  width: 0px;
  height: 100%;
  cursor: pointer; }

.jp-play-bar {
  background: url("../img/jplayer.blue.monday.jpg") 0 -218px repeat-x;
  width: 0px;
  height: 100%; }

/* The seeking class is added/removed inside jPlayer */
.jp-seeking-bg {
  background: url("../img/jplayer.blue.monday.seeking.gif"); }

/* @end */
/* @group volume controls */
.jp-state-no-volume .jp-volume-controls {
  display: none; }

.jp-volume-controls {
	margin-top: 12px;
 }

.jp-audio-stream .jp-volume-controls {
  left: 70px; }

.jp-video .jp-volume-controls {
  top: 12px;
  left: 50px; }

.jp-volume-controls button {
  display: block;
  position: absolute;
  overflow: hidden;
  text-indent: -9999px;
  border: none;
  cursor: pointer; }

.jp-mute,
.jp-volume-max {
  width: 18px;
  height: 15px; }

.jp-volume-max {
  left: 74px; }

.jp-mute {
  background: url("../img/jplayer.blue.monday.jpg") 0 -170px no-repeat; }

.jp-mute:focus {
  background: url("../img/jplayer.blue.monday.jpg") -19px -170px no-repeat; }

.jp-state-muted .jp-mute {
  background: url("../img/jplayer.blue.monday.jpg") -60px -170px no-repeat; }

.jp-state-muted .jp-mute:focus {
  background: url("../img/jplayer.blue.monday.jpg") -79px -170px no-repeat; }

.jp-volume-max {
  background: url("../img/jplayer.blue.monday.jpg") 0 -186px no-repeat; }

.jp-volume-max:focus {
  background: url("../img/jplayer.blue.monday.jpg") -19px -186px no-repeat; }

.jp-volume-bar {
  position: absolute;
  overflow: hidden;
  background: url("../img/jplayer.blue.monday.jpg") 0 -250px repeat-x;
  top: 5px;
  left: 22px;
  width: 46px;
  height: 5px;
  cursor: pointer; }

.jp-volume-bar-value {
  background: url("../img/jplayer.blue.monday.jpg") 0 -256px repeat-x;
  width: 0px;
  height: 5px; }

/* @end */
/* @group current time and duration */
.jp-current-time,
.jp-duration {
  width: 60px;
  font-size: .64em;
  font-style: oblique; }

.jp-current-time {
  display: inline;
  cursor: default; }

.jp-duration {
  display: inline;
  text-align: right;
  cursor: pointer; }

.jp-video .jp-current-time {
  margin-left: 20px; }

.jp-video .jp-duration {
  margin-right: 20px; }

/* @end */
/* @group playlist */
.jp-details {
  font-weight: bold;
  text-align: center;
  cursor: default; }

.jp-type-single .jp-details,
.jp-type-playlist .jp-details {
  border-top: none; }

.jp-details .jp-title {
  margin: 0;
  padding: 5px 20px;
  font-size: .72em;
  font-weight: bold; }
