h1, h2, h3 {
  font-family: @headings-font-family;
  font-weight: @headings-font-weight;
  line-height: @headings-line-height;
  color: @headings-color;
}

h1,
h2,
h3{
  margin-top: (@line-height-computed / 4);
  margin-bottom: (@line-height-computed / 4);
}
h1 { font-size: @font-size-h1; }
h2 { font-size: @font-size-h2; }
h3 { font-size: @font-size-h3; }
p {
  margin: 0 0 (@line-height-computed / 2);
}

.text-right          { text-align: right; }
.text-center         { text-align: center; }
.text-danger {
  .text-emphasis-variant(@state-danger-text);
}
// Unordered and Ordered lists
ul{
  margin-top: 0;
  margin-bottom: (@line-height-computed / 2);
  ul{
    margin-bottom: 0;
  }
}

// List options

// Unstyled keeps list items block level, just removes default browser padding and list-style
.list-unstyled {
  padding-left: 0;
  list-style: none;
}

// Inline turns list items into inline-block
.list-inline {
  .list-unstyled();
  margin-left: -5px;

  > li {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
  }
}
