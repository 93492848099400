.badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: @font-size-small;
  font-weight: @badge-font-weight;
  color: @badge-color;
  line-height: @badge-line-height;
  vertical-align: baseline;
  white-space: nowrap;
  text-align: center;
  background-color: @badge-bg;
  a.list-group-item.active > &,
  .nav-pills > .active > a > & {
    color: @badge-active-color;
    background-color: @badge-active-bg;
  }
}
