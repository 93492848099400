html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
}
aside,
footer,
header,
section {
  display: block;
}
a {
  background: transparent;
}
a:active,
a:hover {
  outline: 0;
}
strong {
  font-weight: bold;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
img {
  border: 0;
}
button,
input,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
button {
  overflow: visible;
  text-transform: none;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
input {
  line-height: normal;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html {
  font-size: 10px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body {
  font-family: Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333333;
  background-color: #f2f2f2;
}
input,
button {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
a {
  color: #890bb2;
  text-decoration: none;
}
a:hover,
a:focus {
  color: #52076a;
  text-decoration: underline;
}
img {
  vertical-align: middle;
}
.img-responsive {
  display: block;
  width: 100% \9;
  max-width: 100%;
  height: auto;
}
h1,
h2,
h3 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}
h1,
h2,
h3 {
  margin-top: 5px;
  margin-bottom: 5px;
}
h1 {
  font-size: 25px;
}
h2 {
  font-size: 22px;
}
h3 {
  font-size: 21px;
}
p {
  margin: 0 0 10px;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-danger {
  color: #a94442;
}
a.text-danger:hover {
  color: #843534;
}
ul {
  margin-top: 0;
  margin-bottom: 10px;
}
ul ul {
  margin-bottom: 0;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-inline {
  padding-left: 0;
  list-style: none;
  margin-left: -5px;
}
.list-inline > li {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}
.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
.row {
  margin-left: -15px;
  margin-right: -15px;
}
.cx-1, .cs-1, .cm-1, .cx-2, .cs-2, .cm-2, .cx-3, .cs-3, .cm-3, .cx-4, .cs-4, .cm-4, .cx-5, .cs-5, .cm-5, .cx-6, .cs-6, .cm-6, .cx-7, .cs-7, .cm-7, .cx-8, .cs-8, .cm-8, .cx-9, .cs-9, .cm-9, .cx-10, .cs-10, .cm-10, .cx-11, .cs-11, .cm-11, .cx-12, .cs-12, .cm-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.cx-1, .cx-2, .cx-3, .cx-4, .cx-5, .cx-6, .cx-7, .cx-8, .cx-9, .cx-10, .cx-11, .cx-12 {
  float: left;
}
.cx-12 {
  width: 100%;
}
.cx-11 {
  width: 91.66666667%;
}
.cx-10 {
  width: 83.33333333%;
}
.cx-9 {
  width: 75%;
}
.cx-8 {
  width: 66.66666667%;
}
.cx-7 {
  width: 58.33333333%;
}
.cx-6 {
  width: 50%;
}
.cx-5 {
  width: 41.66666667%;
}
.cx-4 {
  width: 33.33333333%;
}
.cx-3 {
  width: 25%;
}
.cx-2 {
  width: 16.66666667%;
}
.cx-1 {
  width: 8.33333333%;
}
@media (min-width: 768px) {
  .cs-1, .cs-2, .cs-3, .cs-4, .cs-5, .cs-6, .cs-7, .cs-8, .cs-9, .cs-10, .cs-11, .cs-12 {
    float: left;
  }
  .cs-12 {
    width: 100%;
  }
  .cs-11 {
    width: 91.66666667%;
  }
  .cs-10 {
    width: 83.33333333%;
  }
  .cs-9 {
    width: 75%;
  }
  .cs-8 {
    width: 66.66666667%;
  }
  .cs-7 {
    width: 58.33333333%;
  }
  .cs-6 {
    width: 50%;
  }
  .cs-5 {
    width: 41.66666667%;
  }
  .cs-4 {
    width: 33.33333333%;
  }
  .cs-3 {
    width: 25%;
  }
  .cs-2 {
    width: 16.66666667%;
  }
  .cs-1 {
    width: 8.33333333%;
  }
}
@media (min-width: 992px) {
  .cm-1, .cm-2, .cm-3, .cm-4, .cm-5, .cm-6, .cm-7, .cm-8, .cm-9, .cm-10, .cm-11, .cm-12 {
    float: left;
  }
  .cm-12 {
    width: 100%;
  }
  .cm-11 {
    width: 91.66666667%;
  }
  .cm-10 {
    width: 83.33333333%;
  }
  .cm-9 {
    width: 75%;
  }
  .cm-8 {
    width: 66.66666667%;
  }
  .cm-7 {
    width: 58.33333333%;
  }
  .cm-6 {
    width: 50%;
  }
  .cm-5 {
    width: 41.66666667%;
  }
  .cm-4 {
    width: 33.33333333%;
  }
  .cm-3 {
    width: 25%;
  }
  .cm-2 {
    width: 16.66666667%;
  }
  .cm-1 {
    width: 8.33333333%;
  }
}
.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555555;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #cccccc;
}
.form-control:focus {
  border-color: #890bb2;
  outline: 0;
}
.form-control::-moz-placeholder {
  color: #cecece;
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: #cecece;
}
.form-control::-webkit-input-placeholder {
  color: #cecece;
}
.form-group {
  margin-bottom: 15px;
}
.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  /* border-radius: @border-radius;*/
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.btn:focus,
.btn:active:focus,
.btn.active:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.btn:hover,
.btn:focus {
  color: #333333;
  text-decoration: none;
}
.btn-primary {
  color: #ffffff;
  background-color: #890bb2;
  border-color: #9b0cca;
  /*&.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &:active,
    &.active {
      background-color: @background;
          border-color: @border;
    }
  }

  .badge {
    color: @background;
    background-color: @color;
  }*/
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  color: #ffffff;
  background-color: #640882;
  border-color: #6f0990;
}
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  background-image: none;
}
.btn-info {
  color: #ffffff;
  background-color: #5bc0de;
  border-color: #46b8da;
  /*&.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &:active,
    &.active {
      background-color: @background;
          border-color: @border;
    }
  }

  .badge {
    color: @background;
    background-color: @color;
  }*/
}
.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.open > .dropdown-toggle.btn-info {
  color: #ffffff;
  background-color: #31b0d5;
  border-color: #269abc;
}
.btn-info:active,
.btn-info.active,
.open > .dropdown-toggle.btn-info {
  background-image: none;
}
.btn-warning {
  color: #ffffff;
  background-color: #f0ad4e;
  border-color: #eea236;
  /*&.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &:active,
    &.active {
      background-color: @background;
          border-color: @border;
    }
  }

  .badge {
    color: @background;
    background-color: @color;
  }*/
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.open > .dropdown-toggle.btn-warning {
  color: #ffffff;
  background-color: #ec971f;
  border-color: #d58512;
}
.btn-warning:active,
.btn-warning.active,
.open > .dropdown-toggle.btn-warning {
  background-image: none;
}
.btn-link {
  color: #890bb2;
  font-weight: normal;
  cursor: pointer;
  border-radius: 0;
}
.btn-link,
.btn-link:active,
.btn-link[disabled],
fieldset[disabled] .btn-link {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-link,
.btn-link:hover,
.btn-link:focus,
.btn-link:active {
  border-color: transparent;
}
.btn-link:hover,
.btn-link:focus {
  color: #52076a;
  text-decoration: underline;
  background-color: transparent;
}
.breadcrumb {
  padding: 8px 15px;
  margin-bottom: 5px;
  list-style: none;
  background-color: #f5f5f5;
}
.breadcrumb > li {
  display: inline-block;
}
.breadcrumb > li + li:before {
  content: ">\00a0";
  padding: 0 5px;
  color: #cccccc;
}
.breadcrumb > .active {
  color: #cecece;
}
.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
}
.pagination > li {
  display: inline;
}
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  color: #890bb2;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  margin-left: -1px;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
}
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  color: #52076a;
  background-color: #eeeeee;
  border-color: #dddddd;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  z-index: 2;
  color: #ffffff;
  background-color: #890bb2;
  border-color: #890bb2;
  cursor: default;
}
.badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: bold;
  color: #ffffff;
  line-height: 1;
  vertical-align: baseline;
  white-space: nowrap;
  text-align: center;
  background-color: #890bb2;
}
a.list-group-item.active > .badge,
.nav-pills > .active > a > .badge {
  color: #890bb2;
  background-color: #ffffff;
}
.list-group {
  margin-bottom: 20px;
  padding-left: 0;
}
.list-group-item {
  position: relative;
  display: block;
  padding: 10px 15px;
  margin-bottom: -1px;
  background-color: #ffffff;
  border: 1px solid #dddddd;
}
.list-group-item:last-child {
  margin-bottom: 0;
}
.list-group-item > .badge {
  float: right;
}
.list-group-item > .badge + .badge {
  margin-right: 5px;
}
a.list-group-item {
  color: #555555;
}
a.list-group-item .list-group-item-heading {
  color: #333333;
}
a.list-group-item:hover,
a.list-group-item:focus {
  text-decoration: none;
  color: #555555;
  background-color: #f5f5f5;
}
.list-group-item-heading {
  margin-top: 0;
  margin-bottom: 5px;
  color: #890bb2 !important;
}
.list-group-item-text {
  margin-bottom: 0;
  line-height: 1.3;
}
.embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  border: 0;
}
.embed-responsive.embed-responsive-16by9 {
  padding-bottom: 56.25%;
}
.embed-responsive.embed-responsive-4by3 {
  padding-bottom: 75%;
}
.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 10px;
  background-color: #ffffff;
  border: 1px solid #ededed;
}
.well-lg {
  padding: 24px;
}
.well-sm {
  padding: 9px;
}
.clearfix:before,
.clearfix:after,
.container:before,
.container:after,
.row:before,
.row:after {
  content: " ";
  display: table;
}
.clearfix:after,
.container:after,
.row:after {
  clear: both;
}
/*
.center-block {
  .center-block();
}*/
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}
@-ms-viewport {
  width: device-width;
}
@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}
.jp-audio *:focus,
.jp-audio-stream *:focus,
.jp-video *:focus {
  outline: none;
}
.jp-audio button::-moz-focus-inner,
.jp-audio-stream button::-moz-focus-inner,
.jp-video button::-moz-focus-inner {
  border: 0;
}
.jp-audio,
.jp-audio-stream,
.jp-interface {
  position: relative;
  width: 100%;
}
.jp-audio .jp-interface {
  height: 40px;
}
.jp-audio-stream .jp-interface {
  height: 40px;
}
/* @group CONTROLS */
.jp-controls-holder {
  clear: both;
  width: 440px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  top: -8px;
  /* This negative value depends on the size of the text in jp-currentTime and jp-duration */
}
.jp-interface .jp-controls {
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.jp-audio .jp-controls {
  /*padding: 20px 20px 0 20px;*/
}
.jp-controls button {
  display: block;
  float: right;
  overflow: hidden;
  text-indent: -9999px;
  border: none;
  cursor: pointer;
}
.jp-play {
  width: 40px;
  height: 40px;
}
.jp-play-list {
  width: 40px;
  height: 40px;
  background: url("../img/jplayer.blue.monday.jpg") 0 0 no-repeat;
  position: absolute;
  left: 0;
}
.jp-play {
  background: url("../img/jplayer.blue.monday.jpg") 0 0 no-repeat;
}
.jp-play:focus {
  background: url("../img/jplayer.blue.monday.jpg") -41px 0 no-repeat;
}
.jp-state-playing .jp-play {
  background: url("../img/jplayer.blue.monday.jpg") 0 -42px no-repeat;
}
.jp-state-playing .jp-play:focus {
  background: url("../img/jplayer.blue.monday.jpg") -41px -42px no-repeat;
}
.jp-stop,
.jp-previous,
.jp-next {
  width: 28px;
  height: 28px;
  margin-top: 6px;
}
.jp-stop {
  background: url("../img/jplayer.blue.monday.jpg") 0 -83px no-repeat;
  margin-left: 10px;
}
.jp-stop:focus {
  background: url("../img/jplayer.blue.monday.jpg") -29px -83px no-repeat;
}
/* @end */
/* @group progress bar */
.jp-progress {
  overflow: hidden;
  background-color: #ddd;
  padding: 0 !important;
}
.jp-audio .jp-progress {
  margin-top: 12px;
  height: 15px;
}
.jp-seek-bar {
  background: url("../img/jplayer.blue.monday.jpg") 0 -202px repeat-x;
  width: 0px;
  height: 100%;
  cursor: pointer;
}
.jp-play-bar {
  background: url("../img/jplayer.blue.monday.jpg") 0 -218px repeat-x;
  width: 0px;
  height: 100%;
}
/* The seeking class is added/removed inside jPlayer */
.jp-seeking-bg {
  background: url("../img/jplayer.blue.monday.seeking.gif");
}
/* @end */
/* @group volume controls */
.jp-state-no-volume .jp-volume-controls {
  display: none;
}
.jp-volume-controls {
  margin-top: 12px;
}
.jp-audio-stream .jp-volume-controls {
  left: 70px;
}
.jp-video .jp-volume-controls {
  top: 12px;
  left: 50px;
}
.jp-volume-controls button {
  display: block;
  position: absolute;
  overflow: hidden;
  text-indent: -9999px;
  border: none;
  cursor: pointer;
}
.jp-mute,
.jp-volume-max {
  width: 18px;
  height: 15px;
}
.jp-volume-max {
  left: 74px;
}
.jp-mute {
  background: url("../img/jplayer.blue.monday.jpg") 0 -170px no-repeat;
}
.jp-mute:focus {
  background: url("../img/jplayer.blue.monday.jpg") -19px -170px no-repeat;
}
.jp-state-muted .jp-mute {
  background: url("../img/jplayer.blue.monday.jpg") -60px -170px no-repeat;
}
.jp-state-muted .jp-mute:focus {
  background: url("../img/jplayer.blue.monday.jpg") -79px -170px no-repeat;
}
.jp-volume-max {
  background: url("../img/jplayer.blue.monday.jpg") 0 -186px no-repeat;
}
.jp-volume-max:focus {
  background: url("../img/jplayer.blue.monday.jpg") -19px -186px no-repeat;
}
.jp-volume-bar {
  position: absolute;
  overflow: hidden;
  background: url("../img/jplayer.blue.monday.jpg") 0 -250px repeat-x;
  top: 5px;
  left: 22px;
  width: 46px;
  height: 5px;
  cursor: pointer;
}
.jp-volume-bar-value {
  background: url("../img/jplayer.blue.monday.jpg") 0 -256px repeat-x;
  width: 0px;
  height: 5px;
}
/* @end */
/* @group current time and duration */
.jp-current-time,
.jp-duration {
  width: 60px;
  font-size: .64em;
  font-style: oblique;
}
.jp-current-time {
  display: inline;
  cursor: default;
}
.jp-duration {
  display: inline;
  text-align: right;
  cursor: pointer;
}
.jp-video .jp-current-time {
  margin-left: 20px;
}
.jp-video .jp-duration {
  margin-right: 20px;
}
/* @end */
/* @group playlist */
.jp-details {
  font-weight: bold;
  text-align: center;
  cursor: default;
}
.jp-type-single .jp-details,
.jp-type-playlist .jp-details {
  border-top: none;
}
.jp-details .jp-title {
  margin: 0;
  padding: 5px 20px;
  font-size: .72em;
  font-weight: bold;
}
.header-top {
  height: 46px;
  margin-bottom: 5px;
}
@media (max-width: 767px) {
  .header-top {
    height: 122px;
  }
}
.footer-copyright,
.header-top {
  background-color: #890bb2;
}
.header-top .logo {
  padding: 8px 0 0 0;
}
#searchForm,
.header-top .usertop {
  padding: 5px 0 0 0;
}
.header-top .btn-link,
.footer-copyright .btn-link {
  color: #fff;
}
.borderly {
  border-left: 4px solid #890bb2;
  padding-left: 4px;
}
.shareButton {
  margin: 10px 0px;
  padding: 0;
}
.shareButton li a {
  margin: 0px;
  padding: 0 4px !important;
  height: 20px;
  width: 20px;
}
.header-top .logo,
.footer-copyright .logo {
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  .pagination > li > a,
  .pagination > li > span {
    padding: 10px 16px;
  }
}
.pub-douxavenir {
  background-image: url('../img/douxavenir320.jpg');
}
.pub-charmetelrose {
  background-image: url('../img/charmetelrose320.jpg');
}
.alyroxresponsive {
  width: 320px;
  height: 100px;
  margin: auto;
}
.alyroxcarreresponsive {
  width: 320px;
  height: 100px;
  margin: auto;
}
.pubKwp {
  margin: 0 auto;
  display: block;
  width: 320px;
  height: 100px;
  background-repeat: none;
  background-color: #fff;
  background-position: center center;
}
.myads {
  padding: 10px;
  margin: 10px auto;
}
.intextads {
  margin-left: -20px;
  margin-right: -20px;
}
@media (max-width: 400px) {
  .adaptativeads,
  .adaptativeads .cm-8,
  .adaptativeads .cs-12,
  .adaptativeads .cm-4 {
    padding: 0;
  }
  .adaptativeads .row {
    margin: 0;
  }
  .myads,
  .intextads {
    text-align: center;
    padding: 0;
    border: none;
  }
}
@media (min-width: 400px) {
  .alyroxcarreresponsive {
    width: 180px;
    height: 150px;
  }
  .myads,
  .intextads {
    text-align: center;
    padding: 0;
    border: none;
  }
  .alyroxresponsive {
    width: 468px;
    height: 60px;
  }
  .pub-douxavenir {
    background-image: url('../img/douxavenir320.jpg');
    width: 320px;
    height: 100px;
  }
  .pub-charmetelrose {
    background-image: url('../img/charmetelrose320.jpg');
    width: 320px;
    height: 100px;
  }
}
@media (min-width: 800px) {
  .alyroxcarreresponsive {
    width: 300px;
    height: 600px;
  }
  .alyroxresponsive {
    width: 468px;
    height: 90px;
  }
  .pub-douxavenir {
    background-image: url('../img/douxavenir520.jpg');
    width: 520px;
    height: 100px;
  }
  .pub-charmetelrose {
    background-image: url('../img/charmetelrose520.jpg');
    width: 520px;
    height: 100px;
  }
}
@media (min-width: 1025px) {
  .alyroxcarreresponsive {
    width: 300px;
    height: 600px;
  }
  .alyroxresponsive {
    width: 728px;
    height: 90px;
  }
  .pub-douxavenir {
    background-image: url('../img/douxavenir708.jpg');
    width: 708px;
    height: 89px;
  }
  .pub-charmetelrose {
    background-image: url('../img/charmetelrose708.jpg');
    width: 708px;
    height: 89px;
  }
}
/*# sourceMappingURL=parolesbox.css.map */